
$(document).ready(function() {

    $('ul.navbar-nav li').mouseover(function(){
      var _class = $(this).find('i').attr("class");
      var _class_top = $(this).find('p.top').attr("class");
      if($(this).attr("class") != "active"){
        if(_class.indexOf("white") != -1){
          $(this).find('p.top').attr("class", _class_top.replace('white','red'));
          $(this).find('i').attr("class", _class.replace('white','red'));
        }else{
          $(this).find('p.top').attr("class", _class_top.replace('red','white'));
          $(this).find('i').attr("class", _class.replace('red','white'));
        }
      }
    });
    $('ul.navbar-nav li').mouseout(function(){
      var _class = $(this).find('i').attr("class");
      var _class_top = $(this).find('p.top').attr("class");
      if($(this).attr("class") != "active"){
        if(_class.indexOf("red") != -1){
          $(this).find('p.top').attr("class", _class_top.replace('red','white'));
          $(this).find('i').attr("class", _class.replace('red','white'));
        }else{
          $(this).find('p.top').attr("class", _class_top.replace('white','red'));
          $(this).find('i').attr("class", _class.replace('white','red'));
        }
      }
    });

    $('ul.navbar-nav li a').click(function(event) {
      $('ul.navbar-nav').find('li').removeClass('active');
      var _elem = $('ul.navbar-nav li').find('i');
      _elem.each(function(index, el) {
        $(el).attr("class", $(el).attr("class").replace('red','white'));
      });
      var _elem = $('ul.navbar-nav li').find('p.top');
      _elem.each(function(index, el) {
        $(el).attr("class", $(el).attr("class").replace('red','white'));
      });
      var _class = $(this).find('i').attr("class");
      $(this).parent('li').addClass('active');
      $(this).parent('li').find('p.top').attr('class', 'top red');
      $(this).find('i').attr("class", _class.replace('white','red'));
    });

    // process the form
    $('form').submit(function(event) {

        // get the form data
        // there are many ways to get this data using jQuery (you can use the class or id also)

        $("#contact-result").slideUp();
        $("#contact-loader").fadeIn();

        // name = $('input[name=name]').val();
        // email = $('input[name=email]').val();
        // subject = $('input[name=subject]').val();
        // message = $('textarea[name=message]').val();
        // message = $('input[name=g-recaptcha-response]').val();
        token = $("input[name=_token]").val();

        // process the form
        $.ajax({
            headers: {'X-CSRF-TOKEN': token},
            type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
            url         : baseUrl + 'contact', // the url where we want to POST
            data        : $('form').serialize(),
            dataType    : 'json', // what type of data do we expect back from the server
            encode          : true,
            success : function(data){
              $("#contact-loader").slideUp();
              $("#contact-result").text(data.contact.message)
              if(data.contact.result == "1"){
                $("#contact-result").removeClass("alert-danger");
                $("#contact-result").addClass("alert-success");
              }else{
                $("#contact-result").removeClass("alert-success");
                $("#contact-result").addClass("alert-danger");
              }
              $('#contact form').trigger("reset");
              $("#contact-result").fadeIn();
            },
            error : function(data){
              $("#contact-loader").slideUp();
              $("#contact-result").text("Erro ao enviar mensagem. Tente novamente mais tarde.");
              $("#contact-result").removeClass("alert-success");
              $("#contact-result").addClass("alert-danger");
              $("#contact-result").fadeIn();
            }
        })

        event.preventDefault();
    });
    var fbCloseTitle="close title", fbNextTitle = "next title", fbPrevTitle = "previous title";
    $(".fancybox").fancybox({
        width: '90%',
        height: '90%',
        // fixed: false, // this options doesn't exist
        autoSize: false,
        autoCenter: true,
        tpl: {
            closeBtn: '<div title="' + fbCloseTitle + '" class="fancybox-item fancybox-close"></div>',
            next: '<a title="' + fbNextTitle + '" class="fancybox-item fancybox-next"><span></span></a>',
            prev: '<a title="' + fbPrevTitle + '" class="fancybox-item fancybox-prev"><span></span></a>'
        },
        iframe: {
            preload: false
        }
    });
});